<template>
  <div class="live-big-data">
    <p class="title">直播大数据</p>
    <p class="sub-title">
      找表现最好的直播商品找头部直播间&行业新星找最热门的在播直播间直播间数据分析复盘
    </p>
    <img src="@/assets/images/live-big-data-big-screen.png" />
    <a-button class="stay-tuned">敬请期待</a-button>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
export default {
  components: {
    [Button.name]: Button,
  },
};
</script>

<style lang="less" scoped>
.live-big-data {
  flex: 1;
  min-height: 750px;
  background: url("../../assets/images/live-big-data-bg.jpg") center no-repeat;
  background-size: cover;
  margin-top: -24px;
  margin-bottom: -24px;
  padding-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #ffffff;
    font-size: 44px;
    font-weight: Bold;
    margin-bottom: 39px;
  }

  .sub-title {
    font-size: 24px;
    color: #dddddd;
    font-weight: bold;
    margin-bottom: 48px;
  }

  img {
    width: 1167px;
  }

  .stay-tuned {
    width: 240px;
    height: 60px;
    border-radius: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #edfaff;
    background: linear-gradient(0, #eb7a00, #f9c431);
    border: 0;
    outline: 0;
  }
}
</style>
